import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import style from './style.module.css';
import ValidSMSModal from '../ValidSMSModal';
import ThanksCouponModal from '../ThanksCouponModal';
import RegInput from './RegInput';
import UpdatedModal from './UpdatedModal';
import TermsModal from "../Payment/TermsModal";

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            termsModalIsOpen: false,
            termsContent: '',
            modalIsOpen: false,
            modalUpdIsOpen: false,
            isCheckedTerms: this.props.myShopApiStore.userToken ? true : false,
            localstates: {
                name: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.name : "",
                phone: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.phone : "",
                email: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.email : "",
                city: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.city : "",
                streetName: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.streetName : "",
                streetNumber: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.streetNumber : "",
                entranceCode: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.entranceCode : "",
                apartmentNumber: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.apartmentNumber : "",
                floorNumber: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.floorNumber : "",
                country: this.props.myShopApiStore.userToken ? this.props.myShopApiStore.userParams.country : "",
                state: this.props.myShopApiStore.userToken && !['Israel'].includes(this.props.myShopApiStore.shopData?.country.name) ? this.props.myShopApiStore.userParams.state : ( ['Israel'].includes(this.props.myShopApiStore.shopData?.country.name) ? undefined : '' ),
                zipCode: this.props.myShopApiStore.userToken && !['Israel'].includes(this.props.myShopApiStore.shopData?.country.name) ? this.props.myShopApiStore.userParams.zipCode : ( ['Israel'].includes(this.props.myShopApiStore.shopData?.country.name) ? undefined : '' ),
            }
            //thanksCouponIsOpen: false
        }
        if(!this.props.myShopApiStore.userToken) {
            Object.keys(this.props.myShopApiStore.userParams).forEach((key) => {
                if( key != 'country' )
                    this.props.myShopApiStore.setUserParams(key, '');
            });
        }
        
    }
    
    //closeThanksCouponModal = () => {
    //    this.setState({thanksCouponIsOpen: false});
    //}

    componentDidMount = async () => {
        this.setState({
            errors: []
        });
    }

    handleChange = (event) => {
        const { name, value, checked } = event.target;

        if(name === 'isCheckedTerms'){
            this.setState({[name]: checked})
        }
        else{
            this.setState({
                localstates: {
                    ...this.state.localstates,
                    [name]: value
                }
            });
        }

        this.setState({
            errors: this.state.errors.filter(error => error !== name),
            isButtonDisabled: false
        });
    };

    handleSubmit = async () => {
        const { validateUserParams, shopData, UserLocalParams } = this.props.myShopApiStore;

        const errors = validateUserParams(
            [ 'name', 'phone' ],
            this.state.localstates
        );

        if(!this.state.isCheckedTerms){
            errors.push('isCheckedTerms')
        }

        this.setState({
            errors: errors
        });

        if(!errors.length) {
            Object.entries(this.props.myShopApiStore.userParams).forEach(([key, value]) => {
                if( key != 'country' ) 
                    this.props.myShopApiStore.setUserParams(key, this.state.localstates[key])
            });

            this.setState({isButtonDisabled: true});
            setTimeout(() => this.setState({isButtonDisabled: false}),4000);
            const resp = await this.props.myShopApiStore.registerUser();
            if(resp?.data) this.setState({modalUpdIsOpen: true});
            else this.setState({modalIsOpen: true});
        }
    }

    openInModal = (e) => this.setState({
        termsModalIsOpen: true, 
        termsContent: e.target.id
    });

    render() {
        const { t } = this.props;
        const { country } = this.props.myShopApiStore.shopData;
        return (
        <div className={style.container}>
            {/*<ThanksCouponModal modalIsOpen={this.state.thanksCouponIsOpen} closeModal={this.closeThanksCouponModal}/>*/}
            <div className={style.Header}> {t('Hot Welcome')}<br/> {t('Will be happy')} </div>
            <div className={style.FormContainer}>
                <div className={style.Form}>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                type="text"
                                className = {this.state.errors.includes("name") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={ this.state.localstates.name } 
                                name="name"
                                onChange={this.handleChange} 
                                placeholder={t('full name')+'*'}
                            />
                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            {/* <input 
                                type="tel"
                                className = {this.state.errors.includes("phone") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.props.myShopApiStore.userParams.phone} 
                                name="phone"
                                onChange={this.handleChange} 
                                placeholder={t('mobile phone')}
                                style={{textAlign: document.body.dir === 'rtl' ? 'right' : 'left'}}
                            /> */}
                            <RegInput
                                type="tel"
                                className = {this.state.errors.includes("phone") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.phone}
                                name='phone'
                                onChange={this.handleChange}
                                placeholder={t('mobile phone')+'*'}
                            />

                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                type="email"
                                className = {this.state.errors.includes("email") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.email} 
                                name="email"
                                onChange={this.handleChange} 
                                placeholder={t('e-mail')}
                            />
                        </div>
                    </div>
                    { country && !country.default && country.name !== 'Israel' && <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className={this.state.errors.includes('state') ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.state}
                                name='state'
                                onChange={this.handleChange}
                                placeholder={t('state')}
                            />
                        </div>
                    </div> }
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className = {this.state.errors.includes("city") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.city} 
                                name="city"
                                onChange={this.handleChange} 
                                placeholder={t('city / town')}
                            />
                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className = {this.state.errors.includes("streetName") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.streetName}
                                name="streetName"
                                onChange={this.handleChange} 
                                placeholder={t('street')}
                            />
                        </div>
                    </div>
                    { country && !country.default && country.name !== 'Israel' && <div className={style.FormLine}>
                        <div className={style.FormLineOne}>
                            <input 
                                className={this.state.errors.includes('zipCode') ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.zipCode}
                                name='zipCode'
                                onChange={this.handleChange}
                                placeholder={t('zipCode')}
                            />
                        </div>
                    </div> }
                    <div className={style.FormLine}>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("streetNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.streetNumber} 
                                name="streetNumber"
                                onChange={this.handleChange} 
                                placeholder={t('no. home')}
                            />
                        </div>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("entranceCode") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.entranceCode } 
                                name="entranceCode"
                                onChange={this.handleChange} 
                                placeholder={t('_entry code')}
                            />
                        </div>
                    </div>
                    <div className={style.FormLine}>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("apartmentNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.apartmentNumber} 
                                name="apartmentNumber"
                                onChange={this.handleChange} 
                                placeholder={t('flat number')}
                            />
                        </div>
                        <div className={style.FormLineTwo}>
                            <input 
                                className = {this.state.errors.includes("floorNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                                value={this.state.localstates.floorNumber} 
                                name="floorNumber"
                                onChange={this.handleChange} 
                                placeholder={t('floor')}
                            />
                        </div>
                    </div>
                </div>
                
                <div className={style.TermsLine}>
                    <div className={style.FormLineOne}>
                        <div className={style.CheckTerm}>
                            <input type="checkbox" name="isCheckedTerms" id="isCheckedTerms" checked={this.state.isCheckedTerms} onChange={this.handleChange}/>
                            <span style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : 'rgb(0,0,0)'}}>
                                <label for="isCheckedTerms">
                                    {t('I am approve that I have read')}&nbsp;
                                    <span id='rules' onClick={this.openInModal}>{t('rules')}</span>&#32;{t('and')}&nbsp;
                                    <span id='privacy_policy' onClick={this.openInModal}>{t('privacy policy')}</span>&#32; {t('I agree with all') }
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.RegisterButton} style={ this.state.isButtonDisabled?{backgroundColor: 'rgb(0, 0, 0, 0.2)'}:{}} onClick={this.state.isButtonDisabled ? null : this.handleSubmit}>
                { this.props.myShopApiStore.userToken ? t('Update user data') : t('registration')}
            </div>
            {<ValidSMSModal closeModal={() => this.setState({modalIsOpen: false})} modalIsOpen={this.state.modalIsOpen} action='Registration'/>}
            {<UpdatedModal closeModal={() => this.setState({modalUpdIsOpen: false})} modalIsOpen={this.state.modalUpdIsOpen} action='Registration'/>}
            <TermsModal closeModal={() => this.setState({termsModalIsOpen: false, termsContent: ''}) } 
                    modalIsOpen={this.state.termsModalIsOpen}
                    content={this.state.termsContent} 
                    />
        </div>
        )
    }
}

export default withTranslation()(Registration);